import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { config } from "@fortawesome/fontawesome-svg-core";

import "../sass/index.scss";

config.autoAddCss = false;

const IndexPage = () => (
  <Layout className="main visible">
    <SEO title="Home" />
    <h1>
      sinely<span>.io</span>
    </h1>
    <h2>Coming Soon...</h2>
  </Layout>
);

export default IndexPage;
